import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["small"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    style: {"border-top-color":"transparent"},
    class: _normalizeClass(["aspect-square rounded-full border-2 border-gray-600 animate-spin inline-block", _ctx.classes]),
    small: _ctx.small
  }, null, 10, _hoisted_1))
}