import * as tblHelper from "@/helpers/advancedTableHelper"

export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameAdvancedTableConditionalRow
  const label = options.labelAdvancedTableConditionalRow
  const extendName = options.nameAdvancedTableConditionalBase
  const headerCell = options.nameAdvancedTableCellHeader
  const tableCell = options.nameAdvancedTableCell

  domComponents.addType(name, {
    extend: extendName,
    isComponent: el => el.tagName === 'ADVANCEDTABLECONDITIONALROW',
    model: {
      defaults: {
        nCells: 4,
        isHeaderCells: false,
        name: label,
        tagName: 'tr',
        classes: [name],
        isNew: true,
        isEmpty: true,
        // droppable: options.separatesCell + ',' + 'for-each-container',
        traits: [
          {
            type: 'number',
            name: 'nCells',
            label: 'Number of Cells',
            min: 1,
            changeProp: 1
          },
        ]
      },
      init() {
        this.listenTo(this, 'change:nCells', this.cellsChanged);
      },
      addCells(){
        for (let index = 0; index < this.props().nCells; index++) {
          this.components().add({ type: this.props().isHeaderCells ? headerCell : tableCell }, { at: -1 });
        }
        this.getTrait('nCells').set('value', this.props().nCells)
      },
      cellsChanged(selected, value, opts) {
        if(this.cellCount() === value)
          return

        const baseDifference = value - this.cellCount();
        const difference = Math.abs(value - this.cellCount())
        if(baseDifference<0){
          for(let i=0;i<difference; i++){
            tblHelper.removeCell(this, this.getLastCellIndex())
          }
        } else {
          for(let i=0;i<difference; i++){
            tblHelper.insertCell(this, this.cellCount(), this.props().isHeaderCells ? headerCell : tableCell)
          }
        }
      },
      hasChildren(){
        return this.components().length > 0
      },
      cellCount(){
        return this.components().length
      },
      getLastCellIndex(){
        return this.cellCount() - 1
      },
    },
    view: {
      init() {
        const doc = editor.Canvas.getDocument()
        if (!doc) { return }

        const stl = doc?.querySelector(`style#${name}`);
        if (!stl) {
          doc.body.insertAdjacentHTML('beforeend', `
          <style id=${name}>
            .${name}:empty:after {
              content: "${label}";
              height: 100%;
              font-size: large;
              color: gray;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          </style>
          `);
        } 
      }, 
      events: {
        dblclick: "onDblClick",
      },
      onDblClick() {
        console.log("double")
        editor.Commands.get("open-conditionals").run()
      },
    }
  });
};
