<template>
  <button
    class="px-4 text-sm font-normal rounded-md focus:outline-none focus:ring-2 focus:ring-primary-700"
    :class="classes"
    :disabled="disabled"
    @click.prevent="$emit('click')"
  >
    <div class="text-center" v-if="loading">
      <w-spinner :class="spinnerClasses" small></w-spinner>
      <label v-if="loadingText" class="ml-2.5">{{ loadingText }}</label>
    </div>
    <slot v-else />
  </button>
</template>

<script>
import WSpinner from "../form/WSpinner.vue";

export default {
  components: { WSpinner },
  emits: ["click"],
  props: {
    loadingText: String,
    block: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      // TODO: make the type global, so that ts can check on caller as well
      type: String,
      default: "primary",
    },
  },
  computed: {
    classes() {
      return {
        "w-full": this.block,
        "text-primaryGrapesJs-100 bg-primaryGrapesJs-400": !this.selected && this.mode == "primary",
        "text-gray-500 bg-primaryGrapesJs-900 border border-gray-500": !this.selected && this.mode == "secondary",
        "text-primary-50 bg-primary-700 border border-primary-700": !this.selected && this.mode == "destructive",
        "text-primary-700 bg-primaryGrapesJs-400 border border-primary-700": this.selected && this.mode == "primary",
        "text-primary-700 bg-primaryGrapesJs-900 border border-primary-700": this.selected && this.mode == "secondary",
        // "hover:bg-primary-200": this.mode == "secondary" && !this.disabled,
        "text-black border border-black": this.mode == "outlined",
        "text-black bg-gray-100": this.mode == "gray",
        "hover:bg-gray-200": this.mode == "gray",
        "disabled:bg-black disabled:text-gray-500 disabled:opacity-25": this.mode == "gray" && this.disabled,
        "py-2": !this.small,
        "py-1": this.small,
        "opacity-50": this.disabled,
        "cursor-default": this.disabled,
      };
    },
    spinnerClasses() {
      return {
        "border-black": this.mode === "gray" || this.mode == "outlined",
        "border-white": this.mode == "primary",
        "border-primary-900": this.mode == "secondary",
      };
    },
  },
};
</script>
