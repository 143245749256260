import { query } from '@/helpers/miscellaneousHelpers'
import * as tblHelper from '@/helpers/advancedTableHelper'
import { refreshEditorSelected } from '@/helpers/miscellaneousHelpers'

export default (editor, options = {}) => {
  const commands = editor.Commands;

  // Advanced Table

  commands.add('open-advanced-table-select-modal', {
    run(editor, sender, opts = {}) {
      const parentIsBody = opts.model.parent().get("tagName") == "body"
      editor.Modal.open({
        title: 'Select Advanced Table Type',
        content: `
          <div class="new-advanced-table-form p-4 space-x-4">
            <input id="advanced-table-button-standard" class="h-16 w-56 cursor-pointer border border-primaryGrapesJs-300 hover:border-primary-700 hover:text-primary-700" type="button" value="Standard" data-component-id="`+ opts.model.cid +`">
            </input>
            <input id="advanced-table-button-for-each" class="h-16 w-56 cursor-pointer border border-primaryGrapesJs-300 hover:border-primary-700 hover:text-primary-700" type="button" value="For-each" data-component-id="`+ opts.model.cid +`">
            </input>
            <input id="advanced-table-button-conditional" ${parentIsBody ? "disabled" : ""} class="h-16 w-56 cursor-pointer border disabled:text-primaryGrapesJs-300 disabled:border-primaryGrapesJs-300 border-primaryGrapesJs-300 hover:border-primary-700 hover:text-primary-700" type="button" value="Conditional" data-component-id="`+ opts.model.cid +`">
            </input>
          <div>
        `,
      }).onceClose(() => {
        if (!opts.model.components() || opts.model.components().length === 0) {
          opts.model.remove();
        }
        this.stopCommand()
      });
    },
    stop(editor) {
      editor.Modal.close();
    },
  });

  commands.add('advanced-table-show-table-operations', () => {
    tblHelper.updateAdvancedTableToolbarSubmenu(
      editor, 
      options.nameAdvancedTableThead, 
      options.nameAdvancedTableTbody, 
      options.nameAdvancedTableConditionalTbody,
      options.nameAdvancedTableForEachTbody, 
      options.nameAdvancedTableTfoot);
  });

  commands.add('advanced-table-add-header', editor => {
    let selected = editor.getSelected();
    if (selected?.is(options.nameAdvancedTable) || selected?.is(options.nameAdvancedConditionalTable) || selected?.is(options.nameAdvancedForEachTable)) {
      const header = tblHelper.addHeader(selected, options.nameAdvancedTableThead)
      editor.select(header)
      // refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-add-body', editor => {
    console.log('body')
    let selected = editor.getSelected();
    if (selected?.is(options.nameAdvancedTable) || selected?.is(options.nameAdvancedConditionalTable) || selected?.is(options.nameAdvancedForEachTable)) {
      const body = tblHelper.addBody(selected, options.nameAdvancedTableTbody, options.nameAdvancedTableTfoot)
      editor.select(body)
      // refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-add-conditional-body', editor => {
    let selected = editor.getSelected();
    if (selected?.is(options.nameAdvancedTable) || selected?.is(options.nameAdvancedConditionalTable) || selected?.is(options.nameAdvancedForEachTable)) {
      const body = tblHelper.addConditionalBody(selected, options.nameAdvancedTableConditionalTbody, options.nameAdvancedTableTfoot)
      editor.select(body)
      // refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-add-for-each-body', editor => {
    let selected = editor.getSelected();
    if (selected?.is(options.nameAdvancedTable) || selected?.is(options.nameAdvancedConditionalTable) || selected?.is(options.nameAdvancedForEachTable)) {
      const body = tblHelper.addForEachBody(selected, options.nameAdvancedTableForEachTbody, options.nameAdvancedTableTfoot)
      editor.select(body)
      // refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-add-footer', editor => {
    let selected = editor.getSelected();
    if (selected?.is(options.nameAdvancedTable) || selected?.is(options.nameAdvancedConditionalTable) || selected?.is(options.nameAdvancedForEachTable)) {
      const footer = tblHelper.addFooter(selected, options.nameAdvancedTableTfoot)
      editor.select(footer)
      // refreshEditorSelected(editor)
    }
  });

  // Advanced Table Body

  commands.add('advanced-table-show-body-operations', () => {
    tblHelper.updateAdvancedTableBodyToolbarSubmenu(
      editor, 
      options.nameAdvancedTableRow, 
      options.nameAdvancedTableForEachRow);
  });

  commands.add('advanced-table-add-rows', {
    run(editor, sender, opts = {}) {
      const selected = editor.getSelected()
      console.log(selected)
      editor.Modal.open({
        title: 'Add rows',
        content: `
          <div class="new-advanced-table-form">
            <div>
              <label for="hasHeaders">Add header row</label>
              <input type="checkbox" class="advanced-table-form-control" value="`+ selected.props()['hasHeaders'] + `" name="hasHeaders" id="hasHeaders">
            </div>
            <div>
              <label for="nRows">Number of table rows</label>
              <input type="number" class="advanced-table-form-control" value="`+ selected.props()['nRows'] +`" name="nRows" id="nRows" min="0">
            </div>
            <div>
              <label for="nColumns">Number of columns</label>
              <input type="number" class="advanced-table-form-control" value="`+ selected.props()['nColumns'] + `" name="nColumns" id="nColumns" min="1">
            </div>
            
          <div>
          <input id="advanced-table-button-add-rows" type="button" value="Add rows" data-component-id="`+ selected.cid +`">
        `,
      }).onceClose(() => {
        if (!selected.components() || selected.components().length === 0) {
          selected.remove();
        }
        this.stopCommand()
      });
    },
    stop(editor) {
      editor.Modal.close();
    },
  });

  commands.add('advanced-table-add-conditional-row', editor => {
    let selected = editor.getSelected();
    selected.set("isEmpty", false)
      const conditionalRow = tblHelper.addConditionalRow(selected, options.nameAdvancedTableConditionalRow)
      editor.select(conditionalRow)
      // refreshEditorSelected(editor)
  });

  commands.add('advanced-table-add-for-each-row', editor => {
    let selected = editor.getSelected();
    selected.set("isEmpty", false)
      const forEachRow = tblHelper.addForEachRow(selected, options.nameAdvancedTableForEachRow)
      editor.select(forEachRow)
      // refreshEditorSelected(editor)
  });

  // Advanced Table Row

  commands.add('advanced-table-show-row-operations', () => {
    tblHelper.updateAdvancedTableRowToolbarSubmenu(
      editor, options.nameAdvancedTableCellHeader, options.nameAdvancedTableCell
      );
  });

  commands.add('advanced-table-add-cells', {
    run(editor, sender, opts = {}) {
      const selected = editor.getSelected()
      editor.Modal.open({
        title: 'Add cells',
        content: `
          <div class="new-advanced-table-form">
            <div>
              <label for="nCells">Number of cells</label>
              <input type="number" class="advanced-table-form-control" value="`+ selected.props()['nCells'] + `" name="nCells" id="nCells" min="1">
            </div>
            <div>
              <label for="isHeaderCells">Header cells</label>
              <input type="checkbox" class="advanced-table-form-control" value="`+ selected.props()['isHeaderCells'] + `" name="isHeaderCells" id="isHeaderCells">
            </div>
          <div>
          <input id="advanced-table-button-add-cells" type="button" value="Add cells" data-component-id="`+ selected.cid +`">
        `,
      }).onceClose(() => {
        if (!selected.components() || selected.components().length === 0) {
          selected.remove();
        }
        this.stopCommand()
      });
    },
    stop(editor) {
      editor.Modal.close();
    },
  });


  // Advanced Table Cell

  commands.add('advanced-table-show-cell-operations', () => {
    tblHelper.updateAdvancedTableCellToolbarSubmenu(editor, "text", options.nameConditionalText);
  });

  commands.add('advanced-table-add-text', editor => {
    let selected = editor.getSelected();
    selected.set("isEmpty", false)
      const text = tblHelper.addText(selected, "text")
      editor.select(text)
      // refreshEditorSelected(editor)
  });

  commands.add('advanced-table-add-conditional-text', editor => {
    let selected = editor.getSelected();
    selected.set("isEmpty", false)
      const conditionalText = tblHelper.addConditionalText(selected, options.nameConditionalText)
      editor.select(conditionalText)
      // refreshEditorSelected(editor)
  });

  // FROM HERE ONWARDS - SORT IT OUT!

  commands.add('advanced-table-show-columns-operations', () => {
    tblHelper.updateAdvancedTableCellToolbarSubmenuRowsColumns(editor, 'columns', 'rows', options.nameAdvancedTableCell, options.nameAdvancedTableCellHeader);
  });

  commands.add('advanced-table-show-rows-operations', () => {
    tblHelper.updateAdvancedTableCellToolbarSubmenuRowsColumns(editor, 'rows', 'columns', options.nameAdvancedTableCell, options.nameAdvancedTableCellHeader);
  });

  commands.add('advanced-table-toggle-header', ()=> {
    console.log("toggle")
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCellHeader)) {
      let table = selected.parent().parent();
      tblHelper.toggleHeaderRow(table, options.nameAdvancedTableRow, options.nameAdvancedTableCellHeader, true)
    }
  });

  commands.add('advanced-table-select', editor => {
    editor.runCommand('core:component-exit');
    editor.runCommand('core:component-exit');
  });

  commands.add('advanced-table-insert-row-above', editor => {
    console.log('insert row above')
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell)) {
      let rowComponent = selected.parent();
      let table = selected.parent().parent();
      tblHelper.insertRow(table, rowComponent.collection.indexOf(rowComponent), options.nameAdvancedTableRow, options.nameAdvancedTableCell, true)
      refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-insert-row-below', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let rowComponent = selected.parent();
      let table = selected.parent().parent();
      tblHelper.insertRow(table, rowComponent.collection.indexOf(rowComponent) + 1, options.nameAdvancedTableRow, options.nameAdvancedTableCell, true)
      refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-insert-column-left', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let table = selected.parent().parent();
      let columnIndex = selected.collection.indexOf(selected);
      tblHelper.insertColumn(table, columnIndex, options.nameAdvancedTableCell, options.nameAdvancedTableCellHeader, true)
      refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-insert-column-right', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let table = selected.parent().parent();
      let columnIndex = selected.collection.indexOf(selected);

      tblHelper.insertColumn(table, columnIndex + 1, options.nameAdvancedTableCell, options.nameAdvancedTableCellHeader, true)
      refreshEditorSelected(editor)
    }
  });

  commands.add('advanced-table-delete-row', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let table = selected.parent().parent();
      editor.selectRemove(selected);
      let rowComponent = selected.parent();
      let rowIndex = rowComponent.collection.indexOf(rowComponent)

      tblHelper.removeRow(table, rowIndex, true)

      if (table.components().length  === 0) {
        table.parent().remove(table);
      }
    }
  });

  commands.add('advanced-table-delete-column', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let table = selected.parent().parent();
      let columnIndex = selected.collection.indexOf(selected);

      editor.selectRemove(selected);
      tblHelper.removeColumn(table, columnIndex, true)

      if (table.components().every(component => component.components().length === 0)) {
        table.parent().remove(table);
      }
    }
  });

  commands.add('advanced-table-merge-cells-right', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let currentColspan = selected.getAttributes()['colspan'] ? selected.getAttributes()['colspan'] : 1;
      let columnIndex = selected.collection.indexOf(selected);
      let rowIndex = selected.parent().collection.indexOf(selected.parent());
      let table = selected.parent().parent();
      let mergedRowsIndexEnd = selected.getAttributes()['rowspan'] > 0 ? selected.getAttributes()['rowspan'] : 1;

      for (let index = 0; index < mergedRowsIndexEnd; index++) {
        let componentToRemove = table.components().at(rowIndex + index).components().at(index === 0 ? columnIndex + 1 : columnIndex )
        componentToRemove.components().forEach(component => {
          selected.append(component.toHTML());
        });
        componentToRemove.remove();
      }

      selected.addAttributes({ 'colspan': ++currentColspan });
      refreshEditorSelected(editor)
      if (columnIndex + 1 == selected.parent().components().length) {
        query('#advanced-table-button-merge-cells-right').style.display = 'none'
      }
    }
  });

  commands.add('advanced-table-merge-cells-down', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell)) {
      let currentColspan = selected.getAttributes()['colspan'] ? selected.getAttributes()['colspan'] : 1;
      let currentRowspan = selected.getAttributes()['rowspan'] ? selected.getAttributes()['rowspan'] : 1;
      let columnIndex = selected.collection.indexOf(selected);
      let rowIndex = selected.parent().collection.indexOf(selected.parent()) + currentRowspan - 1;
      let table = selected.parent().parent();

      for (let index = 0; index < currentColspan; index++) {
        let componentToRemove = table.components().at(rowIndex + 1).components().at(columnIndex)
        componentToRemove.components().forEach(component => {
          selected.append(component.toHTML());
        });
        componentToRemove.remove()
      }

      selected.addAttributes({ 'rowspan': ++currentRowspan });
      refreshEditorSelected(editor)
      if (rowIndex + 2 == table.components().length) {
        query('#advanced-table-button-merge-cells-down').style.display = 'none'
      }
    }
  });

  commands.add('advanced-table-unmerge-cells', editor => {
    let selected = editor.getSelected();
    if (selected.is(options.nameAdvancedTableCell) || selected.is(options.nameAdvancedTableCellHeader)) {
      let currentColspan = selected.getAttributes()['colspan'] ? selected.getAttributes()['colspan'] : 1;
      let currentRowspan = selected.getAttributes()['rowspan'] ? selected.getAttributes()['rowspan'] : 1;
      let columnIndex = selected.collection.indexOf(selected);
      let rowIndex = selected.parent().collection.indexOf(selected.parent());
      let table = selected.parent().parent();

      for (let i = 0; i < currentRowspan; i++) {
        for (let x = 0; x < currentColspan; x++) {
          if (i === 0 && currentColspan === 1) {
            continue
          }
          if (i === 0 && x === 0 && currentColspan > 1) {
            x = 1;
          }
          table.components().at(rowIndex + i).components().add({ type: options.nameAdvancedTableCell }, { at: (i === 0 ? columnIndex + 1 : columnIndex) });
        }
      }

      selected.setAttributes({ 'colspan': 1 });
      selected.setAttributes({ 'rowspan': 1 });

      refreshEditorSelected(editor)
      query('#advanced-table-button-merge-cells-right').style.display = ''
      query('#advanced-table-button-merge-cells-down').style.display = ''
    }
  });
};