<template>
  <div class="flex">
    <w-vertical-divider text="If"></w-vertical-divider>
    <filter-section v-model="blockModel.filter" :variableOptions="variableOptions"></filter-section>
  </div>
  <w-horizontal-divider class="before:border-primary-700 after:border-primary-700"></w-horizontal-divider>
</template>

<script>
import FilterSection from "./FilterSection.vue"
import WHorizontalDivider from "./WHorizontalDivider.vue"
import WVerticalDivider from "./WVerticalDivider.vue"

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    variableOptions: {
      type: Array,
      required: true,
    },
    isElseIfBlock: {
      type: Boolean,
    },
  },
  computed: {
    blockModel: {
      get () { return this.block },
      set (value) { this.$emit('update:block', value) },
    },
  },
  components: { FilterSection, WHorizontalDivider, WVerticalDivider },
}
</script>
