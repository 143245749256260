<template>
  <div class="flex mt-2 space-x-4 text-sm place-items-center">
    <span class="text-base text-gray-400">For each </span>
      <w-text-field :disabled="!this.new" class="w-64" placeholder="e.g. customer" v-model="singleVariableText"></w-text-field>
    <span class="text-base text-gray-400">in </span>
    <w-selector 
      :disabled="!this.new"
      v-model="selectedComponentGroup" 
      class="w-64" 
      :items="groupOptions" 
      itemKey="path" 
      itemText="displayName"
    ></w-selector>
    <div class="flex items-center place-items-center text-primaryGrapesJs-100">
      <w-icon-button v-if="this.new" small @click="insertCondition">add</w-icon-button>
      <w-icon-button v-if="!this.new" small @click="removeCondition">remove</w-icon-button>
    </div>
  </div>
</template>

<script>
import { WSelector, WTextField } from "@/components/bases/form"
import { WIconButton } from "@/components/bases/buttons"

export default {
  emits: ["insertCondition", "removeCondition", "displayTextInserted"],
  props: {
    new: {
      type: Boolean,
    },
    group: {
      type: Object
    },
    componentGroupOptions: {
      type: Array
    },
  },
  watch: {
    componentGroupOptions: function() {
      this.groupOptions = this.componentGroupOptions
    },
  },
  data() {
    return {
      selectedComponentGroup: this.group?.selectedGroup,
      groupOptions: this.componentGroupOptions,
      singleVariableText: this.group?.variableText
    }
  },
  computed: {
    forEachObject() {
      return {
        variableText: this.singleVariableText,
        selectedGroup: this.selectedComponentGroup,
        forEachGroups: this.groupOptions
      }
    },
  },

  methods: {
    insertCondition() {
      if (!this.selectedComponentGroup) {
        confirm("Please enter at least one condition")
        return
      }
      if (!this.singleVariableText || this.singleVariableText.trim().length == 0) {
        confirm("Please enter a name for the variable")
        return
      }
      console.log(this.forEachObject)
      this.$emit("insertCondition", this.forEachObject)
      this.reset()
    },

    removeCondition() {
      this.$emit("removeCondition", this.forEachObject)
    },
    reset() {
      this.selectedComponentGroup = null
      this.groupOptions = null
      this.singleVariableText = ""
    },
  },

  components: {
    WIconButton,
    WTextField,
    WSelector,
  },
}
</script>
