
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    small: Boolean,
  },
  computed: {
    classes() {
      return {
        "w-5": this.small,
        "w-10": !this.small,
      }
    },
  },
})
