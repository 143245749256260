import Condition from "../models/Condition"
import Filter from "../models/Filter"
import IfControlBlock from "../models/IfControlBlock"
import RhsOperand from "../models/RhsOperand"

export function newIfControlBlock(existingElseIf) {
  return new IfControlBlock(newFilterBlock(null, null), "", existingElseIf, null)
}

export function newFilterBlock(logicalOperator, withConditions) {
  return new Filter([], withConditions ? withConditions : [newCondition()], logicalOperator)
}

export function newCondition() {
  return new Condition(
    { displayName: null, path: null }, 
    { value: null, name: null, type: null }, 
    new RhsOperand(null, null), 
    null
    )
}