<template>
  <div v-if="filterBlockModel.filters">
    <div v-for="(filter, index) in filterBlockModel.filters" :key="filter">
      <filter-section 
        v-model="filterBlockModel.filters[index]"
        :variableOptions="variableOptions"
        :parentFilterBlock="filterBlockModel"
        @shouldFlattenFilters="flattenFilters"
      ></filter-section>
      <div v-if="filterBlockModel.logicalOperator" class="flex py-4 space-x-2">
        <w-button 
          small 
          @click="addNewCondition(index)"
        >+ {{ filter.logicalOperator.toUpperCase() }} Rule
        </w-button>
        <w-button 
          small 
          @click="addNewFilter(index, filter.logicalOperator)"
        >+ {{ filterBlockModel.logicalOperator.toUpperCase() }} Rule
        </w-button>
        <w-button 
          small 
          mode="destructive" 
          v-if="filterBlockModel.filters.length > 1" 
          @click="removeFilter(index)"
        >- {{ filterBlockModel.logicalOperator.toUpperCase() }} Rule
        </w-button>
      </div>
      <w-horizontal-divider 
        v-if="filterBlockModel.logicalOperator && index < filterBlockModel.filters.length - 1" 
        :text="filterBlockModel.logicalOperator"
      ></w-horizontal-divider>
    </div>
  </div>
  <div v-if="filterBlockModel.conditions" class="w-[1100px]">
    <div v-for="(condition, index) in filterBlockModel.conditions" :key="condition">
      <div class="flex space-x-2">
        <condition-row 
          v-model="filterBlockModel.conditions[index]"
          :variableOptions="variableOptions"
        ></condition-row>
        <div class="flex items-center mt-2 place-items-center text-primaryGrapesJs-100">
          <w-icon-button v-if="filterBlockModel.conditions.length > 1" small @click="removeCondition(index)">do_not_disturb_on</w-icon-button>
        </div>
      </div>
      <w-horizontal-divider 
        v-if="filterBlockModel.logicalOperator && index < filterBlockModel.conditions.length - 1" 
        :text="filterBlockModel.logicalOperator" 
        narrow
        dashed
      ></w-horizontal-divider>
    </div>
    <div v-if="showAndOrRuleButtons" class="flex py-4 space-x-2">
      <w-button small @click="addAndRule()">+ AND Rule</w-button>
      <w-button small @click="addOrRule()">+ OR Rule</w-button>
    </div>
  </div>
</template>

<script>
import ConditionRow from "./ConditionRow.vue"
import WHorizontalDivider from "./WHorizontalDivider.vue"
import WButton from "@/components/bases/buttons/WButton.vue"
import WIconButton from "@/components/bases/buttons/WIconButton.vue"
import { newFilterBlock, newCondition } from "./helpers/conditionalHelpers"

export default {
  emits: ["shouldFlattenFilters"],
  props: {
    modelValue: {
      type: null,
      required: true,
    },
    variableOptions: {
      type: Array,
      required: true
    },
    parentFilterBlock: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showAndOrRuleButtons: !this.parentFilterBlock
    }
  },
  computed: {
    filterBlockModel: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) },
    },
  },
  methods: {
    addNewCondition(index) {
      const conditions = this.filterBlockModel.filters[index].conditions
      conditions.splice(conditions.length, 0, newCondition())
    },
    addNewFilter(index, logicalOperator) {
      this.filterBlockModel.filters.splice(index + 1, 0, newFilterBlock(logicalOperator))
    },
    removeFilter(index) {
      this.filterBlockModel.filters.splice(index, 1)
      if (this.filterBlockModel.filters.length == 1) { 
        this.filterBlockModel.conditions = this.filterBlockModel.filters[0].conditions
        this.filterBlockModel.logicalOperator = this.filterBlockModel.filters[0].logicalOperator
        this.filterBlockModel.filters = []
        this.showAndOrRuleButtons = true
      }
    },
    addAndRule() {
      switch (this.filterBlockModel.logicalOperator) {
        case null:
          this.filterBlockModel.conditions.push(newCondition())
          this.filterBlockModel.logicalOperator = "and"
          break
        case "and" || null: 
          this.filterBlockModel.conditions.push(newCondition())
          break
        case "or": 
          this.filterBlockModel.filters.push(newFilterBlock("or", this.filterBlockModel.conditions))
          this.filterBlockModel.conditions = []
          this.filterBlockModel.logicalOperator = "and"
          this.filterBlockModel.filters.push(newFilterBlock("or"))
          this.showAndOrRuleButtons = false
          break
      }
    },
    addOrRule() {
      switch (this.filterBlockModel.logicalOperator) {
        case null:
          this.filterBlockModel.conditions.push(newCondition())
          this.filterBlockModel.logicalOperator = "or"
          break
        case "or": 
          this.filterBlockModel.conditions.push(newCondition())
          break
        case "and": 
          this.filterBlockModel.filters.push(newFilterBlock("and", this.filterBlockModel.conditions))
          this.filterBlockModel.conditions = []
          this.filterBlockModel.logicalOperator = "or"
          this.filterBlockModel.filters.push(newFilterBlock("and"))
          this.showAndOrRuleButtons = false
          break
      }
    },
    removeCondition(index) {
      this.filterBlockModel.conditions.splice(index, 1)
      if (this.filterBlockModel.conditions.length == 1 && (!this.parentFilterBlock || this.parentFilterBlock.filters.length < 1)) {
        this.filterBlockModel.logicalOperator = null
      }
      if (this.parentFilterBlock) {
        let shouldFlattenFilters = true
        let allConditions = []
        for (let filter of this.parentFilterBlock.filters) {
          if (filter.conditions.length > 1) {
            shouldFlattenFilters = false
            return
          }
          allConditions.push(...filter.conditions)          
        }
        if (shouldFlattenFilters) {
          this.$emit("shouldFlattenFilters", allConditions, this.parentFilterBlock.logicalOperator)
          console.log("Should flatten filters")
        }
      }
      console.log("Remove condition")
    },
    flattenFilters(allConditions, logicalOperator) {
      console.log(logicalOperator)
      this.filterBlockModel.conditions = allConditions
      this.filterBlockModel.logicalOperator = logicalOperator
      this.filterBlockModel.filters = []
      this.showAndOrRuleButtons = true
    }
  },
  components: { ConditionRow, WHorizontalDivider, WButton, WIconButton },
}
</script>
