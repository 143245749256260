
import WSpinner from "@/components/bases/misc/WSpinner.vue"
import { WDialog } from "@/components/bases/modals"
import { render } from "@/api/render.js"
import RenderDocument from "@/models/render/RenderDocument"
import RenderRequest from "@/models/render/RenderRequest"
import { defineComponent } from "@vue/runtime-core"
import { WAlert, WAlertDescription } from "@/components/bases/alerts"


export default defineComponent({
  props: {
    id: String,
    request: RenderRequest,
    docType: {
      type: String,
      required: true
    }
  },
  watch: {
    id: function() {
      console.log("id changed")
      this.renderTemplate()
    },
    request: function() {
      this.renderTemplate()
    }
  },
  data() {
    return {
      isRendering: false,
      errorMessage: null as null | string,
      render: null as null | RenderDocument,
    }
  },
  computed: {
    fullString() {
      return this.docType == "document" ? 
      "data:application/pdf;base64," + this.render?.body : 
      this.render?.body
    },
  },
  methods: {
    async renderTemplate() {
      const isPdf = this.docType == "document"
      try {
        this.isRendering = true
        this.errorMessage = null
        await this.setThisRender()
        this.errorMessage = null
        this.isRendering = false
        if (!isPdf) { this.displayHtml() }
      } catch (error: any) {
        this.errorMessage = error.message
      } finally {
        this.isRendering = false
      }
    },
    async setThisRender() {
      if (this.id) {
        this.render = await render(RenderRequest.fromTemplate(this.docType == "document", this.id!)) ?? null
      } else if (this.request) {
        console.log(this.request)
        this.render = await render(this.request!) ?? null
      }
    },
    displayHtml() {
      this.$nextTick(() => {
        const object = document.querySelector(".html-container")
        const shadowOpen = object!.attachShadow({ mode: "open" })
        shadowOpen.innerHTML = this.fullString!
      })
    },
    cancelRender() {
      this.errorMessage = null
    },
  },
  components: {
    WDialog,
    WSpinner,
    WAlert,
    WAlertDescription,
  },
})
