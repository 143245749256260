export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameConditionalText
  const label = options.labelConditionalText
  const extendName = "text"

  domComponents.addType(name, {
    extend: extendName,
    isComponent: (el) => el.tagName == "CONDITIONAL",
    model: {
      defaults: {
        name: label,
        tagName: "div",
        displayedText: "Open conditionals to choose text",
        revertComponent: null,
        conditionObjects: [],
        conditionCode: null,
        selectedConditionIndex: -1,
        isNew: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, "change:displayedText", this.render)
      },
      events: {
        dblclick: "onDblClick",
      },

      onRender() {
        this.el.innerHTML = this.model.get("displayedText")
      },
      onDblClick() {
        editor.Commands.get("open-conditionals").run()
      },
    },
  })
}