export default class IfControlBlock {
  filter
  statement
  elseIfBlock // Another 'IfControlBlock'
  elseStatement

  constructor(filter, statement, elseIfBlock, elseStatement) {
    this.filter = filter
    this.statement = statement
    this.elseIfBlock = elseIfBlock
    this.elseStatement = elseStatement
  }

  displayTexts(ifControlBlock, isElseIf) {
    return Object.entries(ifControlBlock).reduce((acc, [key, value]) => {
      if (key == "filter" && value) {
        acc.push((isElseIf ? "Else if" : "If") + " " + value.displayText(value))
      }
      if (key == "elseIfBlock" && value) {
        acc.push(this.displayTexts(value, true)[0])
      }
      if (key == "elseStatement" && value) {
        acc.push(`Else`)
      }
      return acc
    }, [])
  }

  statements(ifControlBlock) {
    const statements = Object.entries(ifControlBlock)
      .filter((entry) => entry[0] != "filter")
      .reduce((acc, [key, value]) => {
        if (key == "statement" && value) {
          acc.push(value)
        }
        if (key == "elseIfBlock" && value) {
          acc.push(this.statements(value))
        }
        if (key == "elseStatement" && value) {
          acc.push(value)
        }
        return acc
      }, [])
    return statements
  }

  codeElements(ifControlBlock) {
    let liquidOperators = []
    let liquidTests = []
    let fullInnerCode = "" // Used for conditional text components
    let simpleInnerCode = "" // Used for conditional table components

    function _ifControlBlockCode(ifControlBlock, isElseIf) {
      // 1. There will always be a filter and a statement
      const filter = ifControlBlock.filter
      // a) If filters is not empty there will always be a logical operator, but check anyway. There are no conditions,
      // but each filter will have a test and conditions with a logical operator, so there will be a third test (AND + OR)
      if (filter.filters.length > 0 && filter.logicalOperator) {
        const filters = filter.filters
        for (let filter of filters) {
          let innerCodes = [] // [contact.name == "Sally", contact.address == "3"]
          for (let condition of filter.conditions) {
            if (condition.needsVariables(condition)) {
              // Get names of variables
              const firstVariable = `variable${liquidOperators.length + 1}`
              const secondVariable = `variable${liquidOperators.length + 2}`

              // Add lhs and rhs to liquidOperators
              const liquidOperatorOne = `assign ${firstVariable} = ${condition.lhsCode(condition)}`
              const liquidOperatorTwo = `assign ${secondVariable} = ${condition.rhsCode(condition)}`
              liquidOperators.push(liquidOperatorOne, liquidOperatorTwo)

              // Add variables to innerCode
              innerCodes.push(condition.innerCode(condition, firstVariable, secondVariable, null))
            } else {
              // No variables required
              innerCodes.push(condition.innerCode(condition, null, null, null))
            }
          }
          //(i) For each filter, create a (liquidTest1) and a liquidthen (assign testvariable1 = true)
          const test = `if ${innerCodes.join(" " + filter.logicalOperator + " ")}` // If contact.name AND contact.address
          liquidTests.push(
            { 
              liquidTest: test, variableName: `testVariable${liquidTests.length + 1}`, 
              liquidThen: `assign testVariable${liquidTests.length + 1} = true` 
            })
        }

        // Inner code for filters
        fullInnerCode += `<span conditionalcondition="${isElseIf ? "elsif" : "if"}` + " " 
        + liquidTests.map((test) => { return test.variableName }).join(" " + filter.logicalOperator + " ") + `">` 
        + ifControlBlock.statement + `</span>`

        simpleInnerCode += liquidTests.map((test) => { return test.variableName }).join(" " + filter.logicalOperator + " ")
      }

      // b) If filters is empty there will always be at least one condition, and if there a multiple conditions there
      // will always be a logical operator
      if (filter.filters.length == 0) {
        if (filter.logicalOperator) {
          let innerCodes = []
          for (let condition of filter.conditions) {
            if (condition.needsVariables(condition)) {
              // Get names of variables
              const firstVariable = `variable${liquidOperators.length + 1}`
              const secondVariable = `variable${liquidOperators.length + 2}`

              // Add lhs and rhs to liquidOperators
              const liquidOperatorOne = `assign ${firstVariable} = ${condition.lhsCode(condition)}`
              const liquidOperatorTwo = `assign ${secondVariable} = ${condition.rhsCode(condition)}`
              liquidOperators.push(liquidOperatorOne, liquidOperatorTwo)

              // Add variables to innerCode
              innerCodes.push(condition.innerCode(condition, firstVariable, secondVariable, null))
            } else {
              console.log("No variables required")
              // No variables required
              innerCodes.push(condition.innerCode(condition, null, null, null))
            }
          }
          fullInnerCode += `<span conditionalcondition="${isElseIf ? "elsif" : "if"}` + " " 
          + innerCodes.join(" " + filter.logicalOperator + " ") + `">` + ifControlBlock.statement + `</span>`

          simpleInnerCode += (isElseIf ? "elsif" : "if" + " ") +  innerCodes.join(" " + filter.logicalOperator + " ")
        } else {
          // There is only one condition, so variables only have to be assigned if the condition has a modifier or
          // the operator is of type dateTime
          const condition = filter.conditions[0]
          console.log(condition.needsVariables(condition))
          if (condition.needsVariables(condition)) {
            // Get names of variables
            const firstVariable = `variable${liquidOperators.length + 1}`
            const secondVariable = `variable${liquidOperators.length + 2}`

            // Add lhs and rhs to liquidOperators
            const liquidOperatorOne = `assign ${firstVariable} = ${condition.lhsCode(condition)}`
            const liquidOperatorTwo = `assign ${secondVariable} = ${condition.rhsCode(condition)}`
            liquidOperators.push(liquidOperatorOne, liquidOperatorTwo)

            // Add variables to innerCode
            fullInnerCode += `<span conditionalcondition="${isElseIf ? "elsif" : "if"}` + " " 
            + condition.innerCode(condition, firstVariable, secondVariable, null) + `">` 
            + ifControlBlock.statement + `</span>`

            simpleInnerCode += (isElseIf ? "elsif" : "if" + " ") + condition.innerCode(condition, firstVariable, secondVariable, null)

          } else {
            // No variables required
            fullInnerCode += `<span conditionalcondition="${isElseIf ? "elsif" : "if"}` + " " 
            + condition.innerCode(condition, null, null, null) + `">` + ifControlBlock.statement + `</span>`

            simpleInnerCode += (isElseIf ? "elsif" : "if" + " ") + condition.innerCode(condition, null, null, null)
          }
        }
      }

      // 2. If there is an else-if statement, recursion and add in true for isElseIf
      if (ifControlBlock.elseIfBlock) {
        _ifControlBlockCode(ifControlBlock.elseIfBlock, true)
      }

      // 3. Else statement
      if (ifControlBlock.elseStatement) {
        fullInnerCode += `<span conditionalcondition="else">` + ifControlBlock.elseStatement + `</span>`
        simpleInnerCode += "else"
      }
    }

    // Run recursive nested method on original IfControlBlock
    _ifControlBlockCode(ifControlBlock, false)

    return { 
      liquidOperators: liquidOperators, 
      liquidTests: liquidTests, 
      fullInnerCode: fullInnerCode, 
      simpleInnerCode: simpleInnerCode 
    }
  }
}
