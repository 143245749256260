export function setConditionalComponentProperties(component, ifControlBlock) {
  const outerComponent = component.isInstanceOf("advanced-table-conditional-base") ? component.parent() : component

  const attributeKeys = Object.keys(outerComponent.getAttributes()).filter(item => item != "id" && !item.startsWith("foreach"))
  outerComponent.removeAttributes(attributeKeys) // Remove all attributes apart from id
  outerComponent.addAttributes({ conditional: "true" })

  const codeElements = ifControlBlock.codeElements(ifControlBlock, false)

  // Assign liquidoperator attributes for each variable
  for (let i = 0; i < codeElements.liquidOperators.length; i++) {
    const attributeKey = `liquidoperator${i + 1}`
    outerComponent.addAttributes({ [attributeKey]: codeElements.liquidOperators[i]})
  }
  outerComponent.addAttributes({ liquid: codeElements.liquidOperators.length })

  // Assign liquidtest and liquidthen attributes for each test
  for (let i = 0; i < codeElements.liquidTests.length; i++) {
    const testAttributeKey = `liquidtest${i + 1}`
    const thenAttributeKey = `liquidthen${i + 1}`

    outerComponent.addAttributes({ [testAttributeKey]: codeElements.liquidTests[i].liquidTest})
    outerComponent.addAttributes({ [thenAttributeKey]: codeElements.liquidTests[i].liquidThen})
  }

  outerComponent.addAttributes({ liquidtest: codeElements.liquidTests.length })

  if (component.isInstanceOf("advanced-table-conditional-base")) {
    component.addAttributes({ conditionalcondition: codeElements.simpleInnerCode }) // This may need to change
  } else {
    component.components(codeElements.fullInnerCode) // Add inner code as sub-components
  }
  component.set("ifControlBlock", ifControlBlock)
  component.set("codeElements", codeElements)
  component.set("statements", ifControlBlock.statements(ifControlBlock))
  component.set("isNew", false)
  component.set("revertComponent", null)
  component.getView().render()
}

function setConditionalTextDisplay(component, selectedIndex) {
  if (component.isInstanceOf("conditional-text") || component.isInstanceOf("advanced-table-conditional-base")) {
    component.set("displayedText", component.attributes.statements[selectedIndex])
    component.set("selectedConditionIndex", selectedIndex)
    component.getView().render()
  }
}

function removeConditionalIfNew(component) {
  if (component.isInstanceOf("conditional-text") || component.isInstanceOf("advanced-table-conditional-base")) {
    if (component.attributes.isNew) {
      const coll = component.collection
      if (component.attributes.revertComponent) {
        const revertComponent = component.attributes.revertComponent
        const at = coll.indexOf(component);
        coll.remove(component);
        coll.add(revertComponent, { at });        
      } else {
        coll.remove(component);
      }
    }
  }
}

export { setConditionalTextDisplay, removeConditionalIfNew }
